<template>
    <div class="col-6">
        <div
            @click="activeImageCustomize()"
            :style="getDivBorderCss(block)">
            <div :style="getPaddingCss(block.style)">
                <LazyImage
style="max-width:100%" :src="block.src" alt  :key="block.src"
/>
               
            </div>
        </div>
    </div>
</template>

<script>
export default{
props:{
    block:Object,
    activeImageCustomize:Function,
    getDivBorderCss:Function
    }
}
</script>
